function generateImages(workName, count, descriptions = [], videos = []) {
  return Array.from({ length: count }, (v, index) => ({
    type: 'image',
    thumbnail: require(`@/assets/img/${workName}/img${index + 1}.jpg`),
    description: descriptions[index] || ''
  })).concat(videos.map(video => ({
    type: 'video',
    videoLink: video.link,  // 비디오 링크 사용
    thumbnail: video.thumbnail,  // 비디오 썸네일(옵션)
    description: video.description  // 비디오 설명
  })));
}


export const imagesData = {
  Waterphoto : {
  images : generateImages('Water', 27),
  title: 'Water Photo Automat, 2024 (Ongoing)',
  statement: `Water Photo Automat, 2024 (Ongoing)`,
  },
  Recent : {
    images : generateImages('Recent', 27, [
      'Pattern beneth on the bride(Invert film)',
      'Captured water flow',
      'Blow up on achive footage',
      'Unstable shape',
      'The wanderer of Mudeungsan Mountain, 1977',
      'Dowsing rod (L)',
      'red eyes staring',
      'Blowup image, Ragpicker Decision Ceremony, Korea national archives',
      'The achivement',
      'Light&Shadowm Layered image',
      'Sunset',
      'Flower',
      'Wanderer on Map #1',
      'Wanderer on Map #2',
      'Wanderer on Map #3',
      'Tree #1',
      'Tree #2',
      'Tree #3',
      'Tree #4',
      '',
      

    ]),
    description : ''
  },
  Research : {
    images : generateImages('Research', 8),
    title: 'Rough draft',
  },
  Network: {
    images: generateImages('Network', 29),
    statement: `네트워크 : 디지털 이미지의 채집과 사진 제작에 관한 실험.
    물리적인 속도와 장소를 가지지 않는 데이터는 네트워크라는 일종의 도로를 통해 빠르게 이동한다. 
    네트워크라고 명명되는 기술적 혜택은 현실의 모든것을 업로드하고, 디지털화하고 있다. 디지털화된 것은 가볍고, 부드럽고 무해한 무언가로 통용된다. 이러한 것들은 대부분 이미지로 만들어져 어디선가 디스플레이된다. 
    그래픽 소프트웨어를 통해 사진이나 시각 이미지를 제작하는 것은 손쉬운 창작의 방식으로 수렴하고 있다. 신체를 통해 무언가를 만들어내는 방식에서 디지털의 도구를 사용해 무언가를 만들어내는 방식으로 이동하고 있다. 
    이러한 방식은 사진작업을 만들어내는 방식과 유사하다고 생각한다. 디지털의 도구를 사용해 이미지를 제작할 수 있는 가능성이 높아지고, 현실에 존재하지 않은 어떤것을 만들어낼 수 있다. 사실과 재현을 바탕으로한 단단한 사진을 제작하는 것에서 벗어나, 연무처럼 일렁거리는 데이터를 사진으로 재현하고자 하는 시도이다. 데이터라는 실체없음을 상상할 수 있는 현실의 사물과 그것을 감각하는 신체성을 작업으로 만들어낸다. 선명하고 매끈한 디지털 이미지와 울퉁불퉁한 물질로서의 사진간의 낙차를 드러내고자 한다. 작업 이미지의 묶음과 각기 다른 지점을 향하고 있는 사진의 속성을 구부려 만나게하고, 작업의 형태로 구현하고자 한다.`,
    title: '이미지 연구, 네트워크 Image Research, Network',
  },
  Ascent: {
    images: generateImages('Ascent', 12),
    statement: `The Ascent 2022 (Comission by Swiss embassy,
    <상승 The Ascent > 작업은 오르려는 현상을 통해 상승이라는 개념을 시각적인 이미지로 제작했다.
    도시풍경에서 흔히 볼 수 있는 높은 건축물이 있는 풍경을 상승이라는 요소로 치환하고, 이러한 개념을 상상할 수 있는 신체의 움직임을 이미지로 재현한다.
    먼저 높은 건축물을 인터넷에서 찾아서 수집하거나, 높은 건물을 사진으로 찍는다.
    이후 두개의 비트로 이미지를 변환하고 종이에 인쇄한다.
    두개의 비트-흑과 백으로 찍혀진 사진을 부분 확대하여 재촬영하고, 원본의 이미지와 혼합하는 과정을 거쳐 하나의 이미지로 만들어낸다.
    동시에 높이 오르려는 신체의 이미지를 함께 병치한다.
    조각난 신체의 일부들은 높은 건물들의 이미지와 병치되며, 상승을 성취하고자하는 신체적 욕구를 드러낸다.
    동시에 희미한 신기루와 같은 이미지들은 상승의 욕구와 정반대의 의미를 제시한다.
    이러한 이미지의 조합을 통해, 도시환경에서 상승을 기반으로 삶의 방식을 은유적으로 드러낸다.`,
    title: '상승 The Ascent 2022 (Comission by Swiss embassy)',
  },
  Firefly: {
    images: generateImages('Firefly', 4, [
      { link: 'https://youtu.be/Ppx-38nK3Hg?feature=shared', description: 'Firefly 프로젝트 비디오' }
    ]),
    statement: `과거의 것을 현재로 불러와 다시 다룬다는 것은 어떤 의미가 있을까? 주제를 추출하고, 작업의 구조를 만들어가는 동시에, 켜켜이 쌓여있는 기록을 들춰보며 의문은 쉽사리 사그라들지 않았다. 미술의 언어를 빌려 만들어낼 작업은 무엇을 명징하게 증명하려는 것도 아니며, 역사를 그대로 전사하는 것도 아니다. 더욱이 과거를 덧씌워 현재의 그럴듯한 무엇을 그리는 것은 아니다. 의미 있는 것, 사실과 같이 증명할만한 가치가 있는 것을 찾아내는 것에는 관심이 없었다. 과거와 현재의 느슨한 연결고리를 들여다보고 고민하는 것이 그나마 쓸모 있었다. 역사적으로 남아있는, 사회적으로 규정되어 있는 대상으로 다루기보다는, 지금 여기에서 관찰 가능한 넝마주이를 탐색했다. 그들이 지금, 여기에서 스스로 존재하는 방식에 대한 탐구가 흥미롭게 다가왔으며, 신기루처럼 있었다가 사라지는 현상을 추적하고 있었다. 밤이면 나타나고, 낮이면 사라지지만 분명히 도시 한편에 자리 잡은 채 발광하고 있는 그들을 상상했다. 넝마주이라는 대상은 분명 여기에 존재하지만, 쉽사리 관찰되지 않는다. 공간이라는 장소에서도 관찰되기 쉽지 않으며, 그들의 삶 또한 조명되지 않는다. 존재하지만 그 존재를 느끼지 못하는 그들을 어떻게든 대상으로 그려내는 것은 피하고 싶었다. 나의 삶의 궤적과 짧게나마 교차하지도 못하는 그들의 일상과 삶을 다룰 수는 없었다. 다만, 내가 포착한 희미한 신기루 같은 장면을 눈앞에 그려내는 것으로 상상할 수 있을 거라 생각했다. 그래서 그들의 과거를 지금과 이어 붙이기보다는 지금 여기에서 그들이 존재하는 방식을 관찰하기로 했다.
    사람들이 활동하는 낮에는 잘 보이지 않지만, 해가 떨어지고 야간이 되면 그들은 거리로 나온다. 쉽사리 끌기 힘들어 보일만큼의 짐을 가지고 다니며, 도시를 배회한다. 밤이면 슬며시 자신의 존재를 드러내는 반딧불이처럼 느껴졌다. 반딧불이는 낮에는 숲 속의 낙엽 및 이나 돌 밑 등에 숨고 밤에 활동한다. 반딧불이처럼 이들 또한 밤에만 스스로의 존재를 드러내고 있었다. 과거의 역사와 시간을 증명하는 것은 사실 쓸모없고 의미없는 일이 아닐까. 이미지로 무언가 만든다는 것은 이야기를 말로 옮기고, 기록을 글로 적는것과는 다르다. 작은 편린을 붙잡고 집요한 질문을 던지기도 하고, 답이 없는 질문을 던지도 하며, 미래를 상상하는 질문이기 때문이다. 작업에서 사실로서의 기록과 시간은 왜곡되고 다른방식으로 서술된다. 조각난 역사와 시간들은 새로운 타임라인에서 사용되고, 다른 의미를 만들어내기도 한다. 조작과 편집, 시간성을 뒤틀어 새로운 판타지를 만들어내는 이미지로 인해 그무엇도 깨끗한 사실로 기록될 수 없다. 광학기계로 기록된 더 현실같은 장면들은 하이퍼 리얼리티(Hiper reallity)를 재현한다.
    인간의 시각으로 관찰 불가능한 부분까지 집요하게 확대가능한 높은 해상도의 이미지는 현실의 명징함을 뛰어넘는다. 명료한 이미지에는 의문이 남지 않는다. 의문이 없는 자리에는 피사체의 형태와 색과 같은 시각정보들만 남는다. 이러한 이미지가 현실의 무엇을 지시하는 것을 생각하기보다, 어떤 현실을 새롭게 만드는지 질문해야한다. 모든것이 기록되고 저장되는 시대에 발굴되지 않은 역사는 존재할 것인가? 아마도 역사와 과거는 기록되지 않은 이전의 시간만을 지칭하게 될 것이다. 무엇을 재현하고 만들것인가. 여전히 모를일이다. 지나간 무엇인가를 추적하고, 온전하게 불러온다는 것은 불가능하다. 그러나 상상과 시각를 통해 만들어진 현실속 그어딘가를 부유하고 있을 것이다.
    `,
    title: `반딧불이 Firefly, 2021`,

  },
  Middleturn: {
    images: generateImages(`Middleturn`, 20 ),
    statement: `<미들턴 Middle turn> 2020-2021 작업은 특정한 피사체나 배경 등의 여러 사진적 요소들을 2D, 3D 그래픽 소프트웨어에서 서로 조합하여, 
    작업의 이미지로 제작했다. 광학적인 촬영으로 만들어낼 수 있는 사진과 3D로 만들어낼 수 있는 소스들을 함께 결합했다. 
    3D 그래픽 소프트웨어에서 사진은 두께가 있는 단단한 배경이 되거나 물체의 표면으로 둔갑한다. 
    이후 가상의 사물을 사진과 충돌시키거나 새로운 성질을 부여하는 동시에 사진의 여러 부분들을 조합해 하나의 사진으로 제작한다. 조합된 가상의 장면을 사진적 이미지로 재현함으로서, 실제 존재할 수 없는 형상을 사진으로 만들어낸다. 작업에 등장하는 주요한 대상은 돌과 돌의 형상을 가진 가상의 사물이다. 우리는 손이라는 신체기관을 통해 대상을 지각한다. 손에 꽉차는 단단함과 무게, 거친 표면의 질감을 통해 돌이라는 대상을 머리속으로 그려낸다. 이러한 일련의 과정은 돌이 찍힌 평면의 사진을 볼때도 동일하게 적용된다. <미들턴 Middle turn> (2020~2021)작업에선 돌의 형상을 한 가상의 사물과 실제 돌의 특성이 혼합되거나 대체되는 지각과정을 사진의 형태로 제작한다. 또한, 가상과 현실의 대상을 지각하는 감각에 대해서도 질문을 던진다. 가상과 사진, 사진과 가상이 결합하는 작업의 과정을 통해 사진이 무엇인가를 재현하는 방식에 대해서 이야기하고자 한다.`,
    title: `미들턴, Middle Turn, 2020-2022`,

  },
  Low: {
    images: generateImages('Low', 28 ),
    statement: 
    `사진은 피사체를 사실적인 이미지로 재현한다. 피사체의 형태를 평면적으로 재현하지만, 사실로서 인지한다. 
    광학적인 시선으로 피사체는 이미지의 평면에 기록되고, 사진으로만 재현가능한 대상으로 남는다.
     평면으로 기록된 대상은 오리지널리티를 상실하지만 현실의 사물로서 인식된다. 사물이 가진 지각가능한 특성이 제거되지만, 
     사진으로 찍힌 사물은 그것이 가진 특정한 형태, 무게, 감각을 여전히 가르키고 있다. 낮고, 빠르게 쏘기, Low Quickdraw (2019~2020)>에서 물성의 감각을 돌이라는 특정한 사물을 통해 사진 작업을 통해 시각화하는 과정을 거친다. 3차원의 피사체를 사진의 평면 위에 올려놓을 때 개별 특성을 제거하며, 동시에 시각적인 정보만을 제공한다. 물성이라는 특성을 압축적인 시각 정보로만 드러내며, 현실의 피사체 특성을 이차원의 평면에서 해석 가능한 시각 정보만 남기고 제거하고 변용한다. 사진 매체는 대상을 사진의 표면위에 구체적인 형태로 재현하며, 사진의 표면 위에 올려진 대상은 삼차원의 시공간에서 떨어져 나온 이미지의 단면으로 재현된다. 수많은 경험의 편린이 모여 하나의 물성을 입체적으로 지각하는 것처럼, 사진에서 드러나는 여러 이미지의 단면을 통해 특정한 대상을 상상하게 한다. 물성은 특정한 감각만을 지칭하지 않는다. 무게, 촉감, 부피, 형태와 같이 신체기관의 지각가능한 감각을 통해 수렴한다. 이러한 물성을 돌이라는 구체적인 사물을 통해 점차 상실하는 물성의 감각을 가볍고 얇은 형태로 불러온다. 사진으로 재현하는 시각화 과정 통해 현대인이 점차 상실하는 물성이라는 감각을 이미지를 통해 재현한다. 작업을 통해 물성이라는 감각을 지각하는 동시대의 인식에 대해 언급하고자 한다.`,
    title: `낮고, 빠르게 쏘기, Low, Quickdraw, 2019~2020`,

  },
  Illusion: {
    images : generateImages('Illusion', 17 ),
    statement: `환상계단 작업은 실존하지 않는 대상을, 물성을 가진 오브젝트로 치환시키는 과정에 방점이 있다. 
    사진 매체를 이용하여 실존하지 않는 대상을 얇은 사진의 레이어위에 올려 놓는다. 
    물성으로서 사진이미지로 치환하는 과정에서 드러나는 얇은 레이어-사진에 대해 탐구한다. 사진으로 치환되는 대상들은 사진안에서 실존하지 않는 ‘영’이라는 존재들로 표현된다. 또한 북서울의 지역에서 진행한 일련의 퍼포먼스를 통해 추락과 상승하는 삶의 단면을 드러낸다.    `,
    title: `환상계단 Illusion Stair, 2017(Comission by SeMA)`

  },
  Signal: {
    images:  generateImages('Signal', 65 ),
    statement : `시간이 지나 잊혀지거나 현재에 머물러 있지 못한 존재들은 어떤 모습으로 유영하고 있을까. 
    이미 역사속으로 사라진 존재들은 현재에 속하지도 않고 과거에 속하지도 않는다. 사진이라는 영원한 현재에 머물러 있을 뿐이다. 
    이러한 대상들은 귀신이라는 존재로 구분할 수 있다. 실존하지는 않지만 사진으로 실체를 인정받는다. 이러한 대상들은 사진이라는 매체를 통하여 이미지의 표면에 직접적으로 투사되며 얇은 시간의 레이어 위에 놓여지게 된다. 모든 이미지들은 얇은 시간의 레이어를 가지고 있으며 서로 다른 맥락속에 놓여질때 다른 의미를 가지게 된다. 과거의 시간과 분리된 대상들은 현실세계의 어느곳에도 존재하지 않으며 시간과 시간의 사이에 유영하고 있다. 각각의 이미지들은 시간의 신호를 발하며 서로 다른 이미지들과 일정한 거리를 유지하며 발하고 있다. 새로운 이야기에 존재하는 ‘귀신’들은 원본 세계와는 다른 의미를 가지게 된다. 허구의 세계에서 그들은 변형된 환영으로 표현된다. 허구의 세계에서 ‘귀신’들은 본래의 시간으로 돌아갈 수 없다.    `,
    title : `사이신호 Between Signal`
  },
    Weight:  {
      images: generateImages('Weight', 12 ),
      title : `회한의 무게 Weight of remoresful 2013~2014`,
      statement: `나의 기억과 연관된 대상은 그 기억을 떠올리는데 중요한 역할을 한다. 
      이러한 대상을 얼음 속에 얼리는 행위는 기억을 간직하려는 나의 욕망을 보여준다. 동시에 서서히 녹고 있는 것은 영원히 간직할 수 없다는 걸 알려준다. 
      이 작업을 처음 시작하게 된 동기는 키우던 개가 죽으면서 시작되었다. 처음으로 가장 가까운 대상의 죽음은 나에게 이상한 감정으로 다가왔다. 몇 년 동안 그 죽음을 마주하지 않다가, 그것을 무덤이라는 대상으로 마주했을 때 느꼈던 감정은 새로웠다. 상실감도 아니고 슬픈 것도 아니었다. 다른 대상으로 변형된 그것을 마주했을 때, 마치 기억을 다시 떠올리는 것과 같은 느낌이었다. 나에게 기억이라는 것은 특정한 시점에 존재하는 것은 아니다. 과거의 기억이 특정한 시간에 머물러 있지 않고 흐르는 시간 속에서 함께 움직인다고 생각한다. 기억은 하나의 특정한 시간과 때가 아니라 흐르는 시간 속에서 함께 움직인다. 아주 사소한 기억들이 나의 주위에 살아있고 함께 있다. 특별한 사물, 또는 특정한 시간만이 의미 있는 것은 아니다. 잊혀진 것들과 주위에 있는 것들 또한 나의 한 부분이다. 그것들은 언제나 나와 연관되어 있고 나를 이루는 하나의 개체와도 같다. 사진으로 찍히는 순간 이것들은 나의 새로운 추억과 기억이 된다. 또한 나의 역사를 가지고 있으며 나를 확인시켜준다.`,
  } 
};

export default imagesData;
