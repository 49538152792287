<template>
  <div class="network-gallery-page">
    <div class="network-image-section" @wheel.prevent="handleScroll">
      <div class="network-work-title">
        <h5>{{ networkData.title }}</h5>
      </div>
      <div class="network-gallery-container">
        <div class="network-gallery-wrapper">
          <div class="network-img-wrap" v-for="item in networkData.images" :key="item.thumbnail || item.videoLink">
            <img v-if="item.type === 'image'" v-lazy="item.thumbnail" :alt="item.description" class="network-responsive-image fade-in" @error="setDefaultImage($event)">
            <iframe v-else-if="item.type === 'video'" v-lazy:src="item.videoLink" frameborder="0" allowfullscreen class="network-video fade-in"></iframe>
          </div>
        </div>
      </div>
    </div>
    <!-- 새로운 텍스트 및 이미지 컨테이너 -->
    <div class="network-text-container">
      <div class="network-text-section">
        <h5>Artist's Statement</h5>
        <p>{{ networkData.statement }}</p>
      </div>
      <!-- Research 데이터 섹션 추가 -->
      <!-- <div class="research-section">
        <h5>{{ researchData.title }}</h5>
        <p>{{ researchData.statement }}</p>
        <div class="research-images">
          <div v-for="image in researchData.images" :key="image.thumbnail" class="research-image-wrap">
            <img v-lazy="image.thumbnail" :alt="image.description" class="research-image fade-in" @error="setDefaultImage($event)">
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { imagesData } from '@/data/images.js';

export default {
  setup() {
    const networkData = computed(() => imagesData.Network || { images: [], title: '제목 없음', statement: '설명 없음.' });
    const researchData = computed(() => imagesData.Research || { images: [], title: '제목 없음', statement: '설명 없음.' });

    const handleScroll = (event) => {
      const container = event.currentTarget;
      container.scrollLeft += event.deltaY; // 세로 스크롤을 좌우 스크롤로 변환
      container.scrollLeft += event.deltaX; // 기존의 좌우 스크롤도 작동하도록
    };

    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png'); // 로딩 실패 시 표시할 이미지 경로
      event.target.classList.add('error-image');
    };

    return {
      networkData,
      researchData,
      handleScroll,
      setDefaultImage
    };
  }
}
</script>

<style scoped>
.network-gallery-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.network-image-section {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.network-work-title {
  text-align: left;
  top: 0;
  z-index: 1;
  padding: 10px 0;
}

.network-text-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.network-text-section, .network-images-section, .research-section {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-left: 2px dashed #cfff00;
}

.network-img-wrap {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
}

.network-responsive-image, .network-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 리서치 */
.research-image-wrap {
  padding-right: 5px;
}

.research-images {
  display: flex;
  flex-wrap: wrap;
  object-fit: cover;
  height: 100%;
}

.research-image {
  max-width: 250px;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .network-responsive-image, .network-video, .research-image {
    max-width: 450px;
  }
}

@media (max-width: 768px) {
  .network-gallery-page {
    flex-direction: column;
  }
  .network-responsive-image, .network-video, .research-image {
    max-width: 550px;
  }
  .network-image-section, .network-text-container {
    width: 100%;
  }
  .network-text-section {
    order: -1;
  }
}
</style>
