<template>
  <div class="container">
    <div class="txtframe">
      <div class="txtwrap" v-for="(item, index) in items" :key="index">
        <h4 v-html="item.title"></h4>
        <span v-html="item.contentINFO"></span>
        <div class="divider"></div>
        <p v-html="item.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { textData } from '@/data/text.js';

export default {
  name: 'ArtStateView',
  data() {
    return {
      items: textData
    };
  },
}
</script>

<style scoped>
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 1.5em;
  }
  .txtframe {
    margin: 0;
    padding: 0;
    width: 100%;
  }
 
  h4, p {
    margin: 0 0 0.5em 0;
  }

  .txtwrap {
    margin-bottom: 1em;
    width: calc(100% * 0.55 - 25px);
  }
  .divider {
    width: 30%;
    border-bottom: 1px dashed gray;
    margin-bottom: 10px;
    padding-top: 5px;
  }

  /* 화면 너비가 600px 이하일 때 스타일 */
  @media screen and (max-width: 768px) {
    .txtwrap {
      width: 100%;
    }
  }
</style>
