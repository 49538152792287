<template>
  <div id="app">
    <div class="menu-wrap">
      <div class="home-link-container">
        <router-link class="home-link" to="/" style="color: #cfff00;">Yuntaejun</router-link>
        <span class="blinking-dot"></span>
      </div>
      <nav class="sub-wrap">
        <div @click="toggleMenu('works')" class="menu-title">
          Works <span>{{ isActive('works') ? '[-]' : '[+]' }}</span>
        </div>
        <div v-show="isActive('works')" class="sub-menu">
          <router-link v-for="work in works" :key="work.id" @click="closeMenu" class="link" :to="work.path">
            <h6>{{ work.title }}</h6>
          </router-link>
        </div>
        <div>
          <router-link @click="closeMenu" class="menu-title" to="/project">Install & Etc</router-link>
        </div>
        <div>
          <router-link class="menu-title" to="/text">Text</router-link>
        </div>
        <div>
          <router-link class="menu-title" to="/about">C.V</router-link>
        </div>
      </nav>
    </div>
    <div class="router-view">
      <router-view />
      <div class="footer">
        <span>©2023. All page content is property of Taejun Yun</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const works = [
  { id: 'Waterphoto', title: 'Water Photo Automat, 2024~ (ongoing)', path: '/work/Waterphoto' },
  { id: 'Ascent', title: '상승 Ascent, 2022', path: '/work/Ascent' },
  { id: 'Firefly', title: '반딧불이 Firefly, 2021', path: '/work/Firefly' },
  { id: 'Middleturn', title: '미들턴 Middle turn, 2020~2022', path: '/work/Middleturn' },
  { id: 'Low', title: '낮고, 빠르게 쏘기 Low, Quickdraw, 2019~2020', path: '/work/Low' },
  { id: 'Illusion', title: '환상계단 Illusion Stair, 2017', path: '/work/Illusion' },
  { id: 'Signal', title: '사이신호 Between Signal. 2016', path: '/work/Signal' },
  { id: 'Weight', title: '회한의무게 Weight of remorse, 2015', path: '/work/Weight' }
];
const activeMenus = ref([]);

const toggleMenu = (menu) => {
  const index = activeMenus.value.indexOf(menu);
  if (index > -1) {
    activeMenus.value.splice(index, 1);  // 메뉴가 이미 활성화되어 있으면 제거
  } else {
    activeMenus.value.push(menu);       // 메뉴가 비활성화되어 있으면 추가
  }
};

const isActive = (menu) => activeMenus.value.includes(menu);
const closeMenu = () => { activeMenus.value = []; }
</script>


<style scoped>
.menu-wrap {
  display: flex;
  justify-content: space-between;
  z-index: 999;
  margin-right: 0; /* 오른쪽 여백 제거 */
}

.home-link-container {
  display: flex;
  align-items: center;
}
.sub-wrap {
  display: flex;
  width: 65%;
  justify-content: space-between;
}
.router-view {
  margin-top: 1em;
  z-index: 0;
  position: relative;
}
/* Footer */
.footer {
  text-align: right;
}
/* Menu Styles */
.menu-title {
  cursor: pointer;
  position: relative; /* 부모 요소를 기준으로 자식 요소 위치 */
}

.sub-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3em;
  left: 1em;
  color: white;
  border: 2px dashed #cfff00;
  z-index: 1000;
  padding: 10px; /* 메뉴 내부의 여백 */
  border-radius: 5px; /* 모서리 둥글게 처리 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 상자 그림자 추가 */
  line-height: 2;
  h6 {
    color: white;
  }
}

/* Blinking Dot */
.blinking-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #cfff00;
  border-radius: 50%;
  margin-left: 8px;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

/* Media Queries */
@media screen and (min-width: 320px) and (max-width: 768px) {
  .sub-menu {
    top: 3em;
    left: 1em;
  }
}
</style>
