import { createApp } from 'vue';
import App from './App';
import router from './router';
import VueGtag from 'vue-gtag';
import VueLazyLoad from 'vue-lazyload-next';
import VueEasyLightbox from 'vue-easy-lightbox'; // VueEasyLightbox 추가
import './global.css'; // 전역 CSS 파일 가져오기

const app = createApp(App);

app.use(router);
app.use(VueGtag, {
  config: { id: 'G-VFTY5VZBFC' }
});
app.use(VueLazyLoad, {
  preLoad: 1.3,
  error: 'path/to/error.png',
  attempt: 1
});

app.component('VueEasyLightbox', VueEasyLightbox); // VueEasyLightbox를 전역 컴포넌트로 등록

app.mount('#app');
