<template>
  <div class="gallery-page">
    <div class="image-section" @wheel.prevent="handleScroll">
      <div class="work-title">
        <h5>{{ episodesData.title }}</h5>
      </div>
      <div class="gallery-container">
        <div class="gallery-wrapper">
          <div
            class="img-wrap"
            v-for="(episode, index) in episodesData.images"
            :key="episode.thumbnail || episode.videoLink"
          >
            <img
              v-if="episode.type === 'image'"
              :src="episode.thumbnail"
              :alt="`Image ${index + 1}: ${episode.description}`"
              class="responsive-image fade-in"
              @click="showViewer(index)"
              @error="setDefaultImage($event)"
            />
            <iframe
              v-else-if="episode.type === 'video'"
              :src="episode.videoLink"
              frameborder="0"
              allowfullscreen
              class="video fade-in"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="text-section">
      <h5>작가노트 Artist's statement</h5>
      <p>{{ episodesData.statement }}</p>
    </div>

    <!-- Lightbox 컴포넌트 -->
    <vue-easy-lightbox
      v-if="viewerVisible"
      :imgs="imageUrls"
      :index="currentIndex"
      @hide="viewerVisible = false"
      :z-index="9999"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { imagesData } from '@/data/images.js';
import VueEasyLightbox from 'vue-easy-lightbox';

export default {
  name: 'ImageCompView',
  components: {
    VueEasyLightbox,
  },
  setup() {
    const route = useRoute();
    const viewerVisible = ref(false);
    const currentIndex = ref(0);

    const episodesData = computed(() => {
      const data = imagesData[route.params.workId];
      if (data) {
        return data;
      } else {
        console.error(`No data found for workId: ${route.params.workId}`);
        return {
          images: [],
          title: '등록된 제목이 없습니다.',
          statement: '등록된 아티스트 스테이트먼트가 없습니다.',
        };
      }
    });

    const imageUrls = computed(() =>
      episodesData.value.images
        .filter((img) => img.type === 'image')
        .map((img) => img.thumbnail)
    );

    const handleScroll = (event) => {
      const container = event.currentTarget;
      container.scrollLeft += event.deltaY;
      container.scrollLeft += event.deltaX;
    };

    const setDefaultImage = (event) => {
      event.target.src = require('@/assets/placeholder-image.png');
      event.target.classList.add('error-image');
    };

    const showViewer = (index) => {
      console.log("Image clicked, index: ", index);
      currentIndex.value = index;
      viewerVisible.value = true;
    };

    return {
      episodesData,
      handleScroll,
      setDefaultImage,
      showViewer,
      viewerVisible,
      currentIndex,
      imageUrls,
    };
  },
};
</script>

<style scoped>
/* 기본 스타일 */
.gallery-page {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.gallery-wrapper {
  vertical-align: top;
  display: inline-block;
}

.image-section {
  width: 75%;
  margin-top: 10px;
  overflow-x: auto;
  min-height: 100%;
  white-space: nowrap;
}

.work-title {
  text-align: left;
  top: 0;
  z-index: 1;
  padding-bottom: 10px;
}

.text-section {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  margin-top: 10px;
  width: 25%;
  overflow-y: auto;
  border-left: 2px dashed #cfff00;
}

.img-wrap {
  display: inline-block;
  vertical-align: top;
  padding-right: 5px;
  width: auto;
  height: 100%;
}

.img-wrap img {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.responsive-image {
  max-width: 100%;
  max-height: 100%;
}

.video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

@media (min-width: 1024px) {
  .responsive-image,
  .video {
    max-height: 650px;
  }
}

@media (max-width: 1024px) {
  .gallery-page {
    flex-direction: column;
  }
  .image-section {
    width: 100%;
    overflow-x: auto;
  }
  .img-wrap img {
    width: 100%;
    height: 480px;
    object-fit: cover;
  }
  .text-section {
    width: 100%;
    order: -1;
  }
}

/* VueEasyLightbox의 CSS 수정 */

/* z-index 문제 해결 */
.vue-easy-lightbox__overlay {
  z-index: 10000 !important;
}

/* 이미지 크기 조정 */
.vue-easy-lightbox__img {
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
}

/* 오버레이 배경색 변경 */
.vue-easy-lightbox__overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
