import { createRouter, createWebHistory } from 'vue-router';
import MainView from '@/components/MainView.vue';
import CvCompView from '@/components/CvCompView.vue';
import ArtStateView from '@/components/ArtStateView.vue';
import ImageCompView from '@/components/ImageCompView.vue';
import AproComp from '@/components/project/AproComp.vue';
import NetworkGalleryView from '@/components/work/NetworkGalleryView.vue';

const routes = [
  { path: '/', name: 'MainView', component: MainView },
  { path: '/about', name: 'CvCompView', component: CvCompView },
  { path: '/text', name: 'ArtStateView', component: ArtStateView },
  { path: '/work/:workId', name: 'ImageCompView', component: ImageCompView },
  { path: '/research/:networkId', name: 'NetworkGalleryView', component: NetworkGalleryView },
  { path: '/project', name: 'AproComp', component: AproComp }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
